import {createRouter, createWebHistory} from 'vue-router';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {path: '/', component: () => import('@/views/lk/RecordsPage')},
    {path: '/consultations', component: () => import('@/views/lk/RecordsPage'), meta: {type: 'C'}},
    {path: '/analysis', component: () => import('@/views/lk/RecordsPage'), meta: {type: 'A'}},
    {path: '/examinations', component: () => import('@/views/lk/RecordsPage'), meta: {type: 'E'}},

    {path: '/tax-certificate', component: () => import('@/views/lk/TaxCertificatePage')},

    {path: '/login', component: () => import('@/views/auth/LoginPage')},
    {path: '/login-password', component: () => import('@/views/auth/PasswordPage')},
    {path: '/registration', component: () => import('@/views/auth/RegistrationPage')},
    {path: '/reset-password', component: () => import('@/views/auth/ForgotPassword.vue')},
    {path: '/reset-success', component: () => import('@/views/auth/ResetSuccess.vue')},

    {path: '/help', component: () => import('@/views/auth/LoginPage')},

    {path: '/:pathMatch(.*)*', redirect: '/login'},
  ]
});

router.beforeEach((to) => {
  document.title = to.meta.title || 'Личный кабинет - Центр Перинатального Здоровья';
});

export default router;