<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>
<style>
* {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Days', Arial, sans-serif;
  /*font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
}
body, html, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.mt-1 {
  margin-top: 0.3rem;
}

.mt-2 {
  margin-top: 0.6rem;
}

.mt-3 {
  margin-top: 0.9rem;
}

.ml-1 {
  margin-left: 0.3rem;
}

.ml-2 {
  margin-left: 0.6rem;
}

.ml-3 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-a {
  margin-left: auto;
}

.p-2 {
  padding: 0.6rem;
}

.p-3 {
  padding: 0.9rem;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.text-green {
  color: #29a068;
}

.text-center {
  text-align: center;
}

.v3dp__datepicker input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dae4fe;
  padding: 0.9rem;
}

.v3dp__datepicker input:focus {
  outline: 1px solid #dae4fe;
}

.link-btn {
  padding: 0.9rem;
  width: 100%;
  border-radius: 30px;
  /*font-weight: bold;*/
  background: linear-gradient(#ffe08c, #ef971d);
  border: none;
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 1024px){
  * {
    font-size: 14px;
  }
}
</style>
